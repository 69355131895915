export default {
  meta_title: 'Learn quantum programming — PennyLane',
  meta_description:
    'The most comprehensive resource for learning quantum computing, quantum machine learning (QML), and quantum chemistry, and for practicing quantum programming.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/generic/generic_og.png',
  top_section: {
    title: 'Learn quantum programming with PennyLane',
    sub_title_1:
      'We have entered a new era of quantum computing, where increasingly advanced quantum devices are used to drive an active field of research and applications. Choose an area of interest to get started.',
    sub_title_2: '',
    link_cards: [
      {
        link: '/qml/quantum-computing',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/QC.svg',
        alt: '',
        title: 'Quantum \n Computing',
        description:
          'Learn how universal computation can be achieved using quantum-mechanical systems.',
      },
      {
        link: '/qml/quantum-machine-learning',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/QML.svg',
        alt: '',
        title: 'Quantum Machine Learning',
        description:
          'Find out how the principles of quantum computing and machine learning can be united to create something new.',
      },
      {
        link: '/qml/quantum-chemistry',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/QChem.svg',
        alt: '',
        title: 'Quantum \n Chemistry',
        description:
          'Study the properties of molecules and materials using quantum computers and algorithms.',
      },
    ],
  },
  see_all_content: {
    text: 'See all content',
    link: '#all-content',
  },
  featured_demos: {
    title: 'Featured demos',
    description:
      'Take a deeper dive into quantum computing by exploring cutting-edge algorithms using PennyLane and quantum hardware.',
    tags_description:
      'Choose a category, or have a look at [demos made by our community](/qml/demos_community/).',
  },
  all_content: {
    title: 'All content',
    cards: [
      {
        title: 'Demos',
        description:
          'Dive into the code and see how to apply cutting-edge algorithms and techniques with PennyLane.',
        link: '/qml/demonstrations',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/demos_icon.png',
      },
      {
        title: 'Challenges',
        description:
          'Get hands-on experience with quantum computing in PennyLane using our exclusive coding challenges.',
        link: '/challenges',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/challenges_icon.png',
      },
      {
        title: 'Codebook',
        description:
          'Use PennyLane to explore various quantum computing topics in the PennyLane Codebook.',
        link: '/codebook/',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/codebook_icon.png',
      },
      {
        title: 'Videos',
        description:
          'Follow along with our experts and explore ideas in quantum computing, quantum machine learning, and quantum chemistry.',
        link: '/qml/videos',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/videos_icon.png',
      },
      {
        title: 'Blog',
        description:
          'Keep up with the newest PennyLane features and events, while learning the latest in quantum programming from experts.',
        link: '/blog/',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/Blog_Icon.png',
      },
      {
        title: 'Glossary',
        description:
          'A growing glossary of key concepts for quantum computing, quantum machine learning, and quantum chemistry.',
        link: '/qml/glossary',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/glossary_icon.png',
      },
    ],
  },
  get_started: {
    title: 'Start building with PennyLane',
    /* Buttons -
        - Please limit button text to 25 characters including spaces.
    */
    buttons: [
      {
        text: 'Install PennyLane',
        link: '/install',
      },
      {
        text: 'Explore demos',
        link: '/qml/demonstrations',
      },
    ],
  },
}
